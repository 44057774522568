<template>
  <DefaultLayout width="full">
    <ItemsView entityTypeName="user" />
  </DefaultLayout>
</template>

<script>

import ItemsView from '@/components/common/ItemsView'
import DefaultLayout from '@/components/layout/DefaultLayout'

export default {
  name: 'UsersView',
  components: {DefaultLayout, ItemsView}
}

</script>
